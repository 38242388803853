import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    
    connect() {
        this.typeSelect = document.getElementById('id_field_type')

        if (this.typeSelect) {
            this.enumFieldGroup = document.querySelector('.form-group.field-enumeration')
            this.enumSelect = document.getElementById('id_enumeration')
    
            this.toggleEnumState(this.typeSelect.value)
    
            this.changeHandler = (e) => {
                this.toggleEnumState(e.target.value)
            }
    
            this.typeSelect.addEventListener('change', this.changeHandler, false)
        }
    }

    disconnect() {
        if (this.typeSelect) {
            this.typeSelect.removeEventListener('change', this.changeHandler)
        }
    }

    toggleEnumState(field_type) {
        if(field_type != 'enum') {
            this.enumFieldGroup.classList.remove('is-required')
            this.enumSelect.setAttribute('disabled', 'disabled')
            this.enumSelect.value = ''
        } else {
            this.enumFieldGroup.classList.add('is-required')
            this.enumSelect.removeAttribute('disabled')
        }
    }
}