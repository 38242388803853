import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns'
import { parse, format, add, startOfWeek, endOfWeek } from 'date-fns/esm'
import round from 'lodash/round'
import { CHART_COLORS, Color } from '../../../utils/color';


const DATE_PARSE_FORMATS = {
    'hour': "HH",
    'day': "yyyy-MM-dd",
    'week': "YYYY-ww",
    'month': "yyyy-MM",
}

const DATE_DISPLAY_FORMATS = {
    'hour': "H'h'",
    'day': "d. MMMM yyyy",
    'week': "d. MMM yyyy",
    'month': "MMMM yyyy",
}

const DATE_SCALE_FORMATS = {
    'hour': "H'h'",
    'day': "d. MMM",
    'week': "d.M",
    'month': "MMM yyyy",
}

export default class extends Controller {
    static targets = ['ctx']

    initialize() {
        this.initializeChart()
    }

    initializeChart() {
        const chartData = this.getChartData()
        const lineColor = CHART_COLORS.red
        const barColor = new Color(CHART_COLORS.teal).alpha(0.85).rgbString()
        const datasets = []

        this.enabledAxis = {
            count: false,
            avg: false
        }

        chartData.datasets.forEach((item) => {
            if (item.key == 'count') {
                datasets.push({
                    label: item.label,
                    type: 'bar',
                    backgroundColor: barColor,
                    borderWidth: 0,
                    maxBarThickness: 25,
                    pointStyle: 'rect',
                    yAxisID: 'yCount',
                    data: chartData.data[item.key]
                })
                this.enabledAxis.count = true
            } else {
                datasets.push({
                    label: item.label,
                    type: 'line',
                    fill: false,
                    backgroundColor: item.color || lineColor,
                    borderColor: item.color || lineColor,
                    borderWidth: 3,
                    lineTension: 0.1,
                    pointStyle: 'line',
                    // spanGaps: true,
                    yAxisID: item.axis_id || undefined,
                    data: chartData.data[item.key]
                })

                if (item.axis_id) {
                    this.enabledAxis.avg = true
                }
            }  
        })
        
        this.chart = new Chart(this.ctx, {
            type: 'bar',
            data: {
                datasets: datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                parsing: {
                    xAxisKey: 't'
                },
                layout: {
                    autoPadding: true,
                    // padding: {
                    //     left: 20,
                    //     right: 20,
                    // }
                },
                scales: this.getScalesConfig(chartData),
                elements: { 
                    point: { 
                        radius: 0,
                        hitRadius: 5, 
                        hoverRadius: 3
                    } 
                },
                plugins: {
                    tooltip : {
                        mode: 'x',
                        position: 'nearest',
                        callbacks: {
                            title: (tooltipItems) => {
                                if ((chartData.period == 'hour' || chartData.period == 'week') && tooltipItems.length > 0) {
                                    const value = tooltipItems[0].parsed.x
                                    const period = chartData.period
                                    
                                    if (period == 'hour') {
                                        return `${this.formatDate(period, value)} - ${this.formatDate(period, add(value, {hours: 1}))}`
                                    }

                                    if (period == 'week') {
                                        return `${this.formatDate(period, startOfWeek(value))} - ${this.formatDate(period, endOfWeek(value))}`
                                    }
                                }
                                return undefined
                            },
                            label: (tooltipItem) => {
                                const value = tooltipItem.parsed.y
                                const label = typeof value == 'number' ? round(value, 2) : value
                                return `${tooltipItem.dataset.label}: ${label}`
                            }
                        }
                    },
                    legend: {
                        position: 'bottom',
                        title: { 
                            display: false
                        },
                        labels: {
                            color: '#151b1e',
                            font: {
                                size: 12.5
                            },
                            padding: 20,
                            usePointStyle: true,
                        }
                    }
                }
            }
        })
    }

    getChartData() {
        let parsed = JSON.parse(this.data.get('chartData'))
        return {
            period: parsed.period,
            startDate: parse(parsed.start, DATE_PARSE_FORMATS['day'], new Date()),
            endDate: parse(parsed.end, DATE_PARSE_FORMATS['day'], new Date()),
            datasets: parsed.datasets,
            data: parsed.data
        }
    }

    getScalesConfig(chartData) {
        const config = {
            x: {
                type: 'time',
                distribution: 'linear',
                offset: true,
                grid: {
                    display: false,
                },
                ticks: {
                    source: 'auto',
                    autoSkip: true,
                    maxRotation: 90,
                    callback: (value, index, ticks) => {
                        if (chartData.period == 'week') {
                            const weekStart = this.formatDate(chartData.period, startOfWeek(value), DATE_SCALE_FORMATS)
                            const weekEnd = this.formatDate(chartData.period, endOfWeek(value), DATE_SCALE_FORMATS)
                            return `${weekStart} - ${weekEnd}`
                        }
                        return this.formatDate(chartData.period, value, DATE_SCALE_FORMATS)
                    }
                },
                time: {
                    tooltipFormat: this.getTooltipFormat(chartData.period),
                    unit: chartData.period,
                    parser: (value) => {
                        return this.parseDate(chartData.period, value)
                    }
                },
                bounds: 'data'
            }
        }
        
        if (this.enabledAxis.count === true) {
            config.yCount = {
                type: 'linear',
                position: 'left',
                grid: {
                    display: false
                },
                border: {
                    display: false
                },
                ticks: {
                    beginAtZero: true,
                    min: 0
                },
                title: {
                    display: true,
                    text: this.countLabel,
                    padding: 20,
                }
            }
        }

        if (this.enabledAxis.avg === true) {
            config.yAvg = {
                type: 'linear',
                position: 'right',
                grid: {
                    display: true
                },
                border: {
                    display: false
                },
                ticks: {
                    stepSize: 1,
                    suggestedMin: 0
                },
                title: {
                    display: true,
                    text: this.avgGradeLabel,
                    padding: 20,
                }
            }
        }

        return config
    }

    parseDate(period, date) {
        const opts = {}

        if (period == 'week') {
            opts.useAdditionalWeekYearTokens = true
        }

        return parse(date, DATE_PARSE_FORMATS[period], new Date(), opts)
    }

    formatDate(period, date, formats) {
        formats = formats || DATE_DISPLAY_FORMATS
        return format(date, formats[period])
    }

    getTooltipFormat(period) {
        return DATE_DISPLAY_FORMATS[period]
    }

    get ctx() {
        return this.ctxTarget
    }

    get avgGradeLabel() {
        return this.data.get('avgGradeLabel')
    }

    get countLabel() {
        return this.data.get('countLabel')
    }
}