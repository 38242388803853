import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

    connect() {
        this.enabledCheck = document.getElementById('id_autoimport_enabled')
        this.typeSelect = document.getElementById('id_autoimport_format')
        this.delimiterField = document.getElementById('id_csv_delimiter')
        this.quotecharField = document.getElementById('id_csv_quotechar')
        this.csvHeaderField = document.getElementById('id_csv_header')

        this.delimiterField.style.transition = 'background-color 300ms'
        this.quotecharField.style.transition = 'background-color 300ms'
        
        this.toggleRequired(this.enabledCheck.checked)
        this.toggleEnabled(this.typeSelect.value)
        
        this.enabledChangeHandler = function(e) {
            this.toggleRequired(e.target.checked)    
        }.bind(this)

        this.typeChangeHandler = function(e) {
            this.toggleEnabled(e.target.value)
        }.bind(this)

        this.enabledCheck.addEventListener('change', this.enabledChangeHandler, false)
        this.typeSelect.addEventListener('change', this.typeChangeHandler, false)
    }

    disconnect() {
        this.enabledCheck.removeEventListener('change', this.enabledChangeHandler)
        this.typeSelect.removeEventListener('change', this.typeChangeHandler)
    }

    toggleRequired(enabled) {
        this.requiredFields.forEach((field_name) => {
            const field = document.querySelector(`.form-group.field-${ field_name }`)
            if (enabled) {
                field.classList.add('is-required')
            }
            else {
                field.classList.remove('is-required')
            }
        }, this)
    }

    toggleEnabled(format_type) {
        if (format_type == 'csv') {
            this.delimiterField.removeAttribute('disabled')
            this.quotecharField.removeAttribute('disabled')
            this.csvHeaderField.removeAttribute('disabled')
        }
        else {
            this.delimiterField.setAttribute('disabled', 'disabled')
            this.quotecharField.setAttribute('disabled', 'disabled')
            this.csvHeaderField.setAttribute('disabled', 'disabled')
        }
    }

    get requiredFields() {
        return JSON.parse(this.data.get('requiredFields'))
    }
}