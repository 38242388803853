import axios from 'axios'
import { Controller } from '@hotwired/stimulus'
import { eventDispatcher } from '@/libs/json-event'
import { bindInput, unbindInput } from '@/libs/binder'


export default class extends Controller {
    static values = { campaignsFetchUrl: String }

    connect() {
        this.metricInputs = this.element.querySelectorAll('input[id^=id_metric_]')
        this.scaleInput = this.element.querySelector('#id_scale')
        this.npsLayoutInput = this.element.querySelector('#id_nps_layout')
        this.periodInput = this.element.querySelector('#id_period')
        this.dateFromInput = this.element.querySelector('#id_date_from')
        this.dateUntilInput = this.element.querySelector('#id_date_until')
        this.campaignsInput = this.element.querySelector('#id_campaigns')

        this.metricChangeHandler = (e) => {
            this.onMetricValueChange(e.target.value)
        }

        this.metricInputs.forEach((input) => {
            input.addEventListener('change', this.metricChangeHandler, false)
        })

        bindInput(this, this.periodInput, 'period')

        this.onMetricValueChange(this.element.querySelector('input[name="metric"]:checked').value)
        this.onPeriodValueChange(this.periodValue)
    }

    disconnect() {
        this.metricInputs.forEach((input) => {
            input.removeEventListener('change', this.metricChangeHandler)
        })
        unbindInput(this, this.periodInput, 'period')
    }

    onMetricValueChange(value) {
        if (this.npsLayoutInput) {
            this.disableInput(this.npsLayoutInput, value != 'nps')
        }
        if (this.scaleInput) {
            this.disableInput(this.scaleInput, value != 'avg')
        }
        if (this.campaignsInput) {
            this.fetchCampaignsOptions(value)
        }
    }

    periodValueChanged(value, oldValue) {
        this.onPeriodValueChange(value)
    }

    onPeriodValueChange(value) {
        if (value === '') {
            this.disableInput(this.dateFromInput, false)
            this.disableInput(this.dateUntilInput, false)
        } 
        else {
            this.disableInput(this.dateFromInput, true)
            this.disableInput(this.dateUntilInput, true)

            this.dateFromInput.value = ''
            this.dateUntilInput.value = ''
        }
    }

    disableInput(input, condition) {
        if (condition) {
            input.setAttribute('disabled', 'disabled')
        }
        else {
            input.removeAttribute('disabled')
        }
    }

    fetchCampaignsOptions(metric) {
        const data = new FormData()
        data.append('metric', metric)

        axios({
            method: 'post',
            url: this.campaignsFetchUrlValue,
            data: data,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(resp => {
            const selected = [...this.campaignsInput.selectedOptions].reduce((acc, item) => {
                acc.push(item.value)
                return acc
            }, [])
            
            this.campaignsInput.length = 0
            for (const [value, text] of Object.entries(resp.data)) {
                this.campaignsInput.options.add(new Option(text, value, false, selected.indexOf(value) !== -1))
            }
        }).catch(resp => {
            eventDispatcher.create({
                event: 'app:handle-exception',
                selector: '[data-controller="app"]',
                data: {
                    'type': 'network',
                    'resp': resp
                }
            }).dispatch()
        })
    }
}