import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = [ 'outer', 'table', 'tableWrap', 'header', 'headerWrap']

    initialize() {
        this.tableTarget.tHead.classList.add('scrolltable-hidden')

        if (this.isEnoughHeight) {
            this.element.classList.add('flex-height')
        }

        if (!this.initialized) {
            this.cloneHead()
            
            const wrap = document.createElement('div')
            wrap.classList.add('scrolltable-table')
            wrap.setAttribute('data-scrolltable-target', 'tableWrap')
            this.wrapElement(this.tableTarget, wrap)

            this.initialized = true;
        }
        
        this.setWidth()
    }

    cloneHead() {
        const headerTable = document.createElement('table'),
              tHead = this.tableTarget.tHead.cloneNode(true)

        headerTable.setAttribute('class', this.tableTarget.getAttribute('class'))
        headerTable.setAttribute('data-scrolltable-target', 'header')
        tHead.classList.remove('scrolltable-hidden')
        headerTable.appendChild(tHead)

        const wrap = document.createElement('div')
        wrap.classList.add('scrolltable-header')
        wrap.setAttribute('data-scrolltable-target', 'headerWrap')
        wrap.appendChild(headerTable)

        this.tableTarget.parentNode.insertBefore(wrap, this.tableTarget)
    }

    setWidth() {
        if (this.isEnoughHeight) {
            this.element.classList.add('flex-height')
        } else {
            this.element.classList.remove('flex-height')
        }

        const headerRow = this.headerTarget.querySelector('thead tr'),
            tableRow = this.tableTarget.querySelector('thead tr')

        this.headerWrapTarget.style.width = null
        this.tableWrapTarget.style.width = null

        this.headerTarget.style.maxWidth = 'none'
        this.tableTarget.style.maxWidth = 'none'
        this.headerWrapTarget.style.maxWidth = 'none'
        this.tableWrapTarget.style.maxWidth = 'none'

        if (this.tableTarget.offsetWidth > this.outerTarget.offsetWidth) {
            this.headerWrapTarget.style.width = this.tableTarget.offsetWidth + 'px'
            this.tableWrapTarget.style.width = this.tableTarget.offsetWidth + 'px'
            
            this.headerTarget.style.maxWidth = 'none'
            this.tableTarget.style.maxWidth = 'none'
            this.headerWrapTarget.style.maxWidth = 'none'
            this.tableWrapTarget.style.maxWidth = 'none'
        } else {
            this.headerWrapTarget.style.width = null
            this.tableWrapTarget.style.width = null

            this.headerTarget.style.maxWidth = '100%'
            this.tableTarget.style.maxWidth = '100%'
            this.headerWrapTarget.style.maxWidth = '100%'
            this.tableWrapTarget.style.maxWidth = '100%'
        }
        
        Array.from(tableRow.children).forEach((td, i) => {
            headerRow.children[i].style.width = td.offsetWidth + 'px'
        })

        this.element.style.zIndex = 1
    }

    wrapElement(el, wrapper) {
        el.parentNode.insertBefore(wrapper, el);
	    wrapper.appendChild(el);
    }

    get initialized() {
        return this.data.get('initialized')
    }

    set initialized(value) {
        this.data.set('initialized', value)
    }

    get isEnoughHeight() {
        return (window.innerHeight - this.element.offsetTop) / window.innerHeight > 0.6
    }
}