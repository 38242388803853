import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'

export default class extends Controller {

    connect() {
        this.clickHandler = (e) => { this.open(e) }
        this.element.addEventListener('click', this.clickHandler, false)
        this.modal = new Modal(this.dialogId, this.modalOptions)
        this.modalTarget = this.modal ? this.modal._element : null
    }

    disconnect() {
        this.element.removeEventListener('click', this.clickHandler)

        if (this.modal) {
            this.modal.dispose()

            if (this.modalTarget) {
                this.modalTarget.classList.remove('fade', 'show')
                this.modalTarget.style.display = null
                this.modalTarget.classList.add('fade')
            }
        }
    }

    open(e) {
        e.preventDefault()
        this.modal.show(this.element)
    }

    close(e) {
        e.preventDefault()
        this.modal.hide()
    }

    onBeforeCache(e) {
        
    }

    get dialogId() {
        return this.data.get('dialog')
    }

    get modalOptions() {
        return {}
    }
}