import dropdownIcon from '@tabler/icons/outline/selector.svg'

import alignLeftIcon from '@tabler/icons/outline/align-left.svg'
import alignCenterIcon from '@tabler/icons/outline/align-center.svg'
import alignRightIcon from '@tabler/icons/outline/align-right.svg'
import alignJustifyIcon from '@tabler/icons/outline/align-justified.svg'

import boldIcon from '@tabler/icons/outline/bold.svg'
import italicIcon from '@tabler/icons/outline/italic.svg'
import underlineIcon from '@tabler/icons/outline/underline.svg'

import listBulletIcon from '@tabler/icons/outline/list.svg'
import listCheckIcon from '@tabler/icons/outline/list-check.svg'
import listOrderedIcon from '@tabler/icons/outline/list-numbers.svg'

import linkIcon from '@tabler/icons/outline/link.svg'
import photoIcon from '@tabler/icons/outline/photo.svg'
import dividerIcon from '@tabler/icons/outline/separator-horizontal.svg'
import variableIcon from '@tabler/icons/outline/code-plus.svg'

import undoIcon from '@tabler/icons/outline/arrow-back-up.svg'
import redoIcon from '@tabler/icons/outline/arrow-forward-up.svg'

import icons from 'quill/ui/icons'

icons['dropdownSelector'] = dropdownIcon

icons['align'] = {
    '': alignLeftIcon,
    'center': alignCenterIcon,
    'right': alignRightIcon,
    'justify': alignJustifyIcon,
}

icons['bold'] = boldIcon
icons['italic'] = italicIcon
icons['underline'] = underlineIcon

icons['list'] = {
    bullet: listBulletIcon,
    check: listCheckIcon,
    ordered: listOrderedIcon,
}

icons['link'] = linkIcon
icons['image'] = photoIcon
icons['divider'] = dividerIcon
icons['variable'] = variableIcon

icons['undo'] = undoIcon
icons['redo'] = redoIcon

export default icons