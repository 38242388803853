import { Controller } from '@hotwired/stimulus'
import { bindEvent, unbindEvent } from '@/libs/binder'

export default class extends Controller {
    static targets = ['smsFields', 'emailFields']
    
    connect() {        
        this.typeInputs = this.element.querySelectorAll('input[name=appeal_type]')

        this.toggleFields(this.selectedType)

        this.changeHandler = (e) => {
            this.toggleFields(e.target.value)
        }

        this.typeInputs.forEach(item => {
            item.addEventListener('change', this.changeHandler, false)
        })
    }

    disconnect() {
        this.typeInputs.forEach(item => {
            item.removeEventListener('change', this.changeHandler, false)
        })
    }

    toggleFields(appeal_type, animate) {
        if (appeal_type == '1') {
            this.showFields(this.smsFieldsTarget)
            this.hideFields(this.emailFieldsTarget)
        } else {
            this.showFields(this.emailFieldsTarget)
            this.hideFields(this.smsFieldsTarget)
        }
    }

    showFields(fieldsTarget) {
        fieldsTarget.querySelectorAll('textarea').forEach(item => {
            item.removeAttribute('disabled')
        })
        fieldsTarget.classList.remove('hidden')
    }

    hideFields(fieldsTarget) {        
        fieldsTarget.querySelectorAll('textarea').forEach(item => {
            item.setAttribute('disabled', 'disabled')
        })
        fieldsTarget.classList.add('hidden')
    }

    get selectedType() {
        return this.element.querySelector('input[name=appeal_type]:checked').value
    }
}