import { Controller } from '@hotwired/stimulus'


export default class extends Controller {
    static targets = ['formContent']

    async submit(e) {
        const data = new FormData(e.target.form)
        const location = e.target.form.action || window.location.href
        try {
            const resp = await fetch(location, {
                method: 'POST',
                body: data,
                headers: {
                    'Faux-Submit': 'true'
                }
            })
            this.formContentTarget.innerHTML = await resp.text()
        }
        catch(e) {
            console.log(e)
        }
    }
}