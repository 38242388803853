import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'
import * as Turbo from "@hotwired/turbo"


export default class extends Controller {
    static targets = ['dialog', 'content', 'form']

    connect() {
        this.clickHandler = (e) => { this.click(e) }

        document.addEventListener('click', this.clickHandler, false)
        this.modal = new Modal('#ajaxmodal', this.modalOptions)
        this.modalTarget = this.modal ? this.modal._element : null
    }

    disconnect() {
        document.removeEventListener('click', this.clickHandler)
        this.url = undefined

        if (this.modal) {
            this.modal.dispose()
            
            if (this.modalTarget) {
                this.modalTarget.classList.remove('fade', 'show')
                this.modalTarget.style.display = null
                this.modalTarget.classList.add('fade')
            }
        }
    }

    click(e) {
        let el = e.target,
            found = false
        
        while (el && !(found = el.matches('[data-action="ajaxmodal#open"]'))) {
            el = el.parentElement;
        }

        if (found) {
            e.preventDefault()
            let url = el.getAttribute(el.hasAttribute('href') ? 'href' : 'data-ajaxmodal-url')
            let dialog_size = el.getAttribute('data-ajaxmodal-size')

            if (dialog_size) {
                dialog_size = dialog_size.split(' ')
                this.dialogTarget.classList.add(...dialog_size)
            }
            
            this.open(url)
            
            const onHidden = (e) => {
                if (dialog_size) {
                    this.dialogTarget.classList.remove(...dialog_size);
                }
                this.element.removeEventListener('hidden.bs.modal', onHidden)
            }
            
            this.element.addEventListener('hidden.bs.modal', onHidden, false)
        }
    }

    open(url) {
        this.contentTarget.src = url
        this.contentTarget.loaded.then(() => {
            this.modal.show(this.element)
        })
    }

    close(e) {
        let location = document.location.href,
            action = 'replace'

        if (e.detail && e.detail.location) {
            location = e.detail.location
            action = 'advance'
        }

        const onTurboLoad = (e) => {
            Turbo.cache.clear()
            document.removeEventListener('turbo:load', onTurboLoad)
        }
        
        const onHidden = (e) => {
            Turbo.visit(location, {action: action})
            document.addEventListener('turo:load', onTurboLoad)
            this.element.removeEventListener('hidden.bs.modal', onHidden)
        }
        this.element.addEventListener('hidden.bs.modal', onHidden, false)
        
        this.modal.hide()
    }

    get modalOptions() {
        return {}
    }
}