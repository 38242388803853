import { Controller } from '@hotwired/stimulus'
import RichTextEditor from '@/libs/richtext/editor'


export default class extends Controller {
    static targets = ['toolbar', 'editor', 'input', 'linkBtn', 'undoBtn', 'redoBtn']
    static values = {
        format: {type: String, default: 'html'},
        maxlength: {type: Number, default: null},
    }

    connect() {
        this.richtext = new RichTextEditor({
            editor: this.editorTarget,
            input: this.inputTarget,
            toolbar: this.hasToolbarTarget ? this.toolbarTarget : null,
            toolbarItems: {
                link: this.hasLinkBtnTarget ? this.linkBtnTarget : null,
                undo: this.hasUndoBtnTarget ? this.undoBtnTarget : null,
                redo: this.hasRedoBtnTarget ? this.redoBtnTarget : null,
            },
            outputFormat: this.formatValue,
            maxlength: this.maxlengthValue
        })
    }
}