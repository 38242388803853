import { Controller } from '@hotwired/stimulus'
import { bindEvent, unbindEvent } from '@/libs/binder'
import { is } from 'date-fns/locale'

export default class extends Controller {
    connect() {
        this.metricInputs = this.element.querySelectorAll('input[id^=id_metric_]')
        this.typeInputs = this.element.querySelectorAll('input[id^=id_type_]')
        
        this.surveyField = this.element.querySelector('.form-row.field-survey ')
        this.surveyInput = this.surveyField.querySelector('#id_survey')
        this.surveyHelpText = this.surveyField.querySelector('.form-field-help')
        
        bindEvent(this, Array.from(this.metricInputs), 'change', 'metricValueChanged')
        bindEvent(this, Array.from(this.typeInputs), 'change', 'typeValueChanged')
    }

    disconnect() {
        unbindEvent(this, Array.from(this.metricInputs), 'change', 'metricValueChanged')
        unbindEvent(this, Array.from(this.typeInputs), 'change', 'typeValueChanged')
    }
    

    metricValueChanged(e) {
        const value = e.target.value
        let disabledType = null
        let survey_required = false
        
        if (value == 'survey') {
            disabledType = '5'
            survey_required = true
        }
        this.surveyField.classList.toggle('is-required', survey_required)
        this.surveyHelpText.classList.toggle('invisible', survey_required)
        this.disableRadioOption(this.typeInputs, disabledType)
    }

    typeValueChanged(e) {
        const value = e.target.value
        const disabledMetric = (value == '5') ? 'survey' : null
        this.disableRadioOption(this.metricInputs, disabledMetric)
    }

    disableRadioOption(fields, value) {
        fields.forEach(item => {
            item.toggleAttribute('disabled', item.value == value)
            if (item.value == value) {
                item.checked = false
            }
        })
    }



    // connect() {
    //     this.typeSelect = document.getElementById('id_field_type')

    //     if (this.typeSelect) {
    //         this.enumFieldGroup = document.querySelector('.form-group.field-enumeration')
    //         this.enumSelect = document.getElementById('id_enumeration')
    
    //         this.toggleEnumState(this.typeSelect.value)
    
    //         this.changeHandler = (e) => {
    //             this.toggleEnumState(e.target.value)
    //         }
    
    //         this.typeSelect.addEventListener('change', this.changeHandler, false)
    //     }
    // }

    // disconnect() {
    //     if (this.typeSelect) {
    //         this.typeSelect.removeEventListener('change', this.changeHandler)
    //     }
    // }

    // toggleEnumState(field_type) {
    //     if(field_type != 'enum') {
    //         this.enumFieldGroup.classList.remove('is-required')
    //         this.enumSelect.setAttribute('disabled', 'disabled')
    //         this.enumSelect.value = ''
    //     } else {
    //         this.enumFieldGroup.classList.add('is-required')
    //         this.enumSelect.removeAttribute('disabled')
    //     }
    // }
}