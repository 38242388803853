import Quill from 'quill'

import { ClassAttributor, Scope, Registry } from 'parchment'
import { BlockEmbed } from 'quill/blots/block'
import { ImageTooltip } from './tooltip'
import icons from './icons'
import { ngettext, interpolate } from "@/libs/i18n"
import { trimString } from '@/utils/common'

import Block from 'quill/blots/block'
import Break from 'quill/blots/break'
import Container from 'quill/blots/container'
import Cursor from 'quill/blots/cursor'
import Inline from 'quill/blots/inline'
import Scroll from 'quill/blots/scroll'
import Text from 'quill/blots/text'

import Bold from 'quill/formats/bold'
import Italic from 'quill/formats/italic'
import Underline from 'quill/formats/underline'
import { ListContainer, default as ListItem } from 'quill/formats/list'
import Link from 'quill/formats/link'
import Image from 'quill/formats/image'

const richRegistry = new Registry()
const plainRegistry = new Registry()

class DividerBlot extends BlockEmbed {
    static blotName = 'divider'
    static tagName = 'hr'
  }

const SizeClass = new ClassAttributor('size', 'text-size', {
  scope: Scope.INLINE,
  whitelist: ['small', 'large', 'huge'],
})

const AlignClass = new ClassAttributor('align', 'text-align', {
    scope: Scope.BLOCK,
    whitelist: ['right', 'center', 'justify'],
})

plainRegistry.register(
    Block, Break, Container, Cursor, Inline, Scroll, Text,
)

richRegistry.register(
    Block, Break, Container, Cursor, Inline, Scroll, Text,
    Bold, Italic, Underline,
    ListContainer, ListItem,
    Link, Image,
    SizeClass, AlignClass, DividerBlot,
)

// richRegistry.register({
//     'formats/size': SizeClass,
//     'formats/align': AlignClass,
//     'formats/divider': DividerBlot,
// })

export default class RichTextEditor {
    constructor({editor, input, toolbar=null, toolbarItems={}, outputFormat='html', maxlength=null} = {}) {
        this.input = input
        this.toolbar = toolbar
        this.toolbarItems = toolbarItems
        this.outputFormat = outputFormat
        this.maxlength = maxlength
        this.charsleft = this.toolbar && this.maxlength ? this.toolbar.querySelector('.plaintext-charsleft') : null

        this.editor = new Quill(editor, {
            registry: this.outputFormat === 'text' ? plainRegistry : richRegistry,
            modules: {
                toolbar: {
                    container: toolbar,
                    handlers: {
                        image: () => this.imageHandler(),
                        divider: () => this.dividerHandler(),
                        variable: (value) => this.variableHandler(value),
                        undo: () => this.undoHandler(),
                        redo: () => this.redoHandler(),
                    }
                },
                keyboard: {
                    bindings: {
                        'tab': {
                            key: 9,
                            handler: function(range, context) {
                                return true;
                            }
                        }
                    }
                }
            },
            theme: 'snow'
        })


        this.editor.on('text-change', () => {
            this.updateUndoRedoButtons()
            this.updateCharsLeft()
            const contentLength = this.contentLength
            
            if (this.maxlength && contentLength > this.maxlength) {
                this.editor.deleteText(this.maxlength, this.editor.getLength())
            }

            if (contentLength == 0) {
                this.input.value = ''
            }
            else {

                this.input.value = this.outputFormat === 'text' ? this.editor.getText().trim() : this.editor.root.innerHTML.trim()
            }
        })

        this.editor.on('selection-change', (range, oldRange, source) => {
            if (this.toolbarItems.link) {
                this.toolbarItems.link.disabled = range == null || range.length === 0
            }
        })


        this.history = this.editor.getModule('history')
        this.imageTooltip = new ImageTooltip(this.editor, this.editor.options.bounds)
        this.initializeToolbar()

        return this
    }

    initializeToolbar() {
        this.setPickerIcon('variable', icons['variable'])
        this.setPickerIcon('size', icons['dropdownSelector'])
        this.updateUndoRedoButtons()
        
        if (this.toolbarItems.link) {
            this.toolbarItems.link.disabled = true
        }

        this.updateCharsLeft()
    }

    setPickerIcon(name, svgIcon) {
        if (this.toolbar) {
            const picker = this.toolbar.querySelector(`span.ql-${name} .ql-picker-label`)
            if (picker) {
                const icon = picker.querySelector('svg')
                icon.outerHTML = svgIcon
            }
        }
    }

    updateUndoRedoButtons() {
        if (this.toolbarItems.undo) {
            this.toolbarItems.undo.disabled = this.history.stack.undo.length === 0
        }
        if (this.toolbarItems.redo) {
            this.toolbarItems.redo.disabled = this.history.stack.redo.length === 0
        }
    }

    updateCharsLeft() {
        if (this.charsleft) {
            const remaining = this.maxlength - this.contentLength
            const fmts = ngettext(
                '<strong>%s</strong> char left.',
                '<strong>%s</strong> chars left.',
                remaining
            )

            this.charsleft.innerHTML = interpolate(fmts, [remaining])
        }
    }

    imageHandler() {
        if (this.imageTooltip) {
            this.imageTooltip.edit()
        }
    }
    
    dividerHandler() {
        const currentPosition = this.editor.getSelection().index
        this.editor.insertEmbed(currentPosition, 'divider', true)
        this.editor.setSelection(currentPosition + 1)
    }
    
    variableHandler(value) {
        const currentPosition = this.editor.getSelection().index
        this.editor.insertText(currentPosition, value)
        this.editor.setSelection(currentPosition + value.length)
    }

    undoHandler() {
        this.editor.history.undo()
    }

    redoHandler() {
        this.editor.history.redo()
    }

    get contentLength() {
        const length = trimString(this.editor.getText(), '\n').length
        return length == 1 && this.editor.getText() == '\n' ? 0 : length
    }
}
